import React from 'react'
import styles from './AppLogo.module.css'
import DiglotLogo from '../../ui-kit/DiglotLogo'

function AppLogo() {
  return (
    <div className={styles.wrapper}>
      <DiglotLogo className={styles.appLogo} />
      <div>Diglot.ai</div>
    </div>
  )
}

export default AppLogo
