import React, { useState, ReactEventHandler } from 'react'
import { Link } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { sendPasswordResetEmail } from 'firebase/auth'
import { getAuthErrorMessage } from '../utilities/helpers'
import { appDispatch, destroyError, selectError, setError } from '../store'
import { auth } from '../services/firebase'

import Motion from '../ui-kit/Motion'
import styles from './ForgotPass.module.css'
import Button from '../ui-kit/Button'
import Copyright from '../ui-kit/Copyright'
import Input from '../ui-kit/Input'
import Form from '../ui-kit/Form'
import Logo from '../ui-kit/DiglotLogo'

const ForgotPassword = () => {
  const [isValid, setIsValid] = useState(false)
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const error = useSelector(selectError)

  const handleSumbit: ReactEventHandler = (event) => {
    event.preventDefault()
    appDispatch(destroyError())

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setIsSubmitted(true)
      })
      .catch((error) => {
        appDispatch(setError(getAuthErrorMessage(error)))
      })
  }

  const renderForm = () => {
    return (
      <Motion kind="slideFromBottom">
        <div className={styles.title}>
          <h1 className="text--center">Forget Password?</h1>
        </div>
        <div className={styles.subtitle}>
          <p className="text--center">
            No worries. Enter your email and we’ll send you instructions to
            reset your password.
          </p>
        </div>
        <Form
          checkForValidityOn={[email]}
          errorMessage={error}
          validityCallback={setIsValid}
          onSubmit={handleSumbit}
        >
          <Input
            label="Email"
            type="email"
            isRequired
            value={email}
            onChange={setEmail}
            autofocus
          />
          <Button kind={isValid ? 'primary' : 'disabled'}>
            Restore password
          </Button>
        </Form>
      </Motion>
    )
  }

  const renderSubmitted = (): JSX.Element => {
    return (
      <>
        <h1>Check Your Inbox</h1>
        <p>
          If the email address you submitted matches our records, you will
          recieve an email in your inbox from
          <code>noreply@diglot.app</code>. Please follow the instructions
          provided to create a new password.
        </p>
      </>
    )
  }

  return (
    <>
      <div className={styles.column}>
        <div className={styles.formHeader}>
          <a href="https://get.diglot.app">
            <Logo className={styles.appLogo} />
          </a>
          {isSubmitted ? renderSubmitted() : renderForm()}
          <div className={styles.authLinks}>
            {/* <Link to="/" className="footer-link">
              Sign up
            </Link> */}
            <Link to="/sign-in" className="footer-link">
              Sign in
            </Link>
          </div>
          <Copyright />
        </div>
      </div>
    </>
  )
}

export default connect()(ForgotPassword)
